import { render, staticRenderFns } from "./GeneralUserDepartmentTable.vue?vue&type=template&id=37a5799f&scoped=true&"
import script from "./GeneralUserDepartmentTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./GeneralUserDepartmentTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./GeneralUserDepartmentTable.vue?vue&type=style&index=0&id=37a5799f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a5799f",
  null
  
)

export default component.exports